$(function(){
    $('.btn-copy').on('click', function() {
        const el = document.getElementById('clipboard');
        el.style.display = 'inline-block';
        const sel = document.getSelection();
        const rng = document.createRange();
        rng.selectNodeContents(el);
        sel.removeAllRanges();
        sel.addRange(rng);
        const success = document.execCommand("copy");
        el.style.display = 'none';
        if(success) {
            const tooltip = $('.tool-tip');
            $(tooltip).show();
            setTimeout(function() {
                $(tooltip).fadeOut(300);
            }, 1000);
        }
    });

});