$(function(){
    let win = $(window);
    let floating = document.getElementsByClassName('sec-floating');
    $('a[href^="#"]').on('click', function() {
        var speed = 400;
        var href= $(this).attr('href');
        var tgt = $(href == '#' || href == '' ? 'html' : href);
        if(tgt) { var position = tgt.offset().top; }
        $('body, html').animate({
            scrollTop: position
        }, speed, 'swing');
        return false;
    });


    win.on('scroll', function() {
        let scrTop = window.pageYOffset;
        let footPos = $('#g-footer').offset().top;
        let wHeight = window.innerHeight;
        let docH = document.body.clientHeight;
        if(scrTop > (docH - wHeight + footPos) - docH) {
            $(floating).addClass('is-hide');
        } else {
            $(floating).removeClass('is-hide');
        }
    });

});